<template>
  <div class="main-content">

    <div class="text-16-500 model-spu">
      <span>{{ $t('models.spu') }}:</span> {{ model.spu }}
    </div>

    <div class="main-content__inner">
      <div class="model">
        <div class="photo model-section">
          <ProductImage
            class="model-photo"
            :url="model.image"
            :width="80"
            :height="80"
          />

          <div class="text-24-800 model-title">
            <p>{{ model.name }}</p>
            <p v-if="model.nameCn">{{ model.nameCn }}</p>
          </div>
        </div>
        <div class="model-section">
          <div class="model-attributes">
            <div :class="['status', isRejected ? 'red' : 'green']">
              <svg-icon :icon-name="icon" width="24" height="24" class="icon"/>
              {{ model.moderation }}
            </div>

            <Attribute
              v-for="attribute in modelParams"
              :key="attribute.key"
              :attrKey="attribute.key"
              :value="attribute.value"
            />
          </div>

          <div class="model-description" v-html="model.description"></div>
        </div>

        <div class="model-table">
          <div class="model-table-title">
            {{ $t('products') }} <span>({{ model.products.length }})</span>
          </div>
          <ProductTable
            :data="model.products"
            @onChangeProduct="updateProduct"
          />
        </div>
      </div>
      <div class="button-wrap">
        <simple-button
          :value="$t('editModel')"
          :disabled="editIsDisabled"
          :transparent="true"
          @click="$router.push(`/edit/model/${model.id}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import ProductTable from '@/components/ui/product/ProductTable'
import Attribute from '@/components/ui/layout/models/Attribute'
import { mapState } from 'vuex'
import {
  MODEL_APPROVAL_AWAITING,
  MODEL_APPROVAL_REJECTED
} from '@/helpers/consts/model-approval-statuses'
import ProductImage from '../../ui/images/ProductImage'
import SvgIcon from "@shared/components/SvgIcon.vue";

const statusesMap = {
  confirmed: 'confirmed',
}

export default {
  name: 'ModelPage',
  components: {SvgIcon, ProductImage, Attribute, ProductTable, SimpleButton },
  async mounted () {
    await this.getModelById()
  },
  computed: {
    ...mapState({
      model: state => state.products.myModel,
    }),

    isRejected() {
      return this.model.moderation === 'rejected';
    },

    icon() {
      return statusesMap[this.model.moderation];
    },

    modelParams () {
      const brand = {
        key: this.$t('listBrand'),
        value: this.model.brand ? this.model.brand.name : '',
      }
      const category = {
        key: this.$t('models.category'),
        value: this.model.category ? this.model.category.name : '',
      }
      const status = {
        key: this.$t('orderStatus'),
        value: this.model.moderation,
      }

      const result = [
        brand,
        category,
      ]

      if (this.model.type) {
        const type = {
          key: this.$t('models.type'),
          value: this.model.type.name,
        }

        result.unshift(type)
      }

      return result
    },

    editIsDisabled () {
      if (this.model.isOwner) {
        switch (this.model.moderation) {
          case MODEL_APPROVAL_AWAITING:
          case MODEL_APPROVAL_REJECTED:
            return false
          default:
            return true
        }
      }
      return true
    },
  },
  methods: {
    async getModelById () {
      await this.$store.dispatch('getModelById', this.$route.params.modelId)
    },
    async updateProduct (payload) {
      try {
        await this.$store.dispatch('updateProduct', payload)
        this.$store.commit('showHintNotification', {
          description: this.$t('productSuccessfullyUpdated', { id: payload.id }),
        })
      } finally {
        await this.getModelById()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.model {
  &-section {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    &.photo {
      align-items: center;
    }

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  &-description {
    font-size: 14px;
    line-height: 16px;
    color: $text-gray;
    flex: 0 1 auto;
  }

  &-attributes {
    width: 337px;
    padding: 20px;
    border-radius: 16px;
    flex: 0 0 auto;
    background-color: $color-background;

    > .attribute {
      margin-bottom: 8px;
    }

    .status {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &.red {
        color: #D82129;
      }

      &.green {
        color: #019E4F;
      }

      .icon {
        margin-right: 8px;
      }
    }
  }

  &-table-title {
    font-weight: 700;
    margin-bottom: 20px;

    span {
      font-weight: 400;
      color: $text-gray;
    }
  }
}
.model-spu {
  padding: 20px 0;
}

</style>
